// 1. Install dependencies DONE
// 2. Import dependencies DONE
// 3. Setup webcam and canvas DONE
// 4. Define references to those DONE
// 5. Load handpose DONE
// 6. Detect function DONE
// 7. Drawing utilities DONE
// 8. Draw functions DONE

import React, { useRef } from "react";
// import logo from './logo.svg';
// import * as tf from "@tensorflow/tfjs";
// import * as handpose from "@tensorflow-models/handpose";
import Webcam from "react-webcam";
import "./App.css";
import { drawHand } from "./utilities";

import * as handPoseDetection from '@tensorflow-models/hand-pose-detection';
import * as tf from '@tensorflow/tfjs-core';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';

import Unity, { UnityContext } from "react-unity-webgl";

function App() {
  const unityContext = new UnityContext({
    loaderUrl: "unityhand/Build/unityhand.loader.js",
    dataUrl: "unityhand/Build/unityhand.data",
    frameworkUrl: "unityhand/Build/unityhand.framework.js",
    codeUrl: "unityhand/Build/unityhand.wasm",
  });

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const runHandpose = async () => {
    const model = handPoseDetection.SupportedModels.MediaPipeHands;
    const detectorConfig = {
      runtime: 'tfjs',
      modelType: 'lite',
      maxHands: 1
    };
    const detector = await handPoseDetection.createDetector(model, detectorConfig);

    setInterval(() => {
      detect(detector);
    }, 100);

    // const net = await handpose.load();
    // console.log("Handpose model loaded.");
    // //  Loop and detect hands
    // setInterval(() => {
    //   detect(net);
    // }, 100);
  };

  const detect = async (detector) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      const estimationConfig = { flipHorizontal: false };
      const hands = await detector.estimateHands(video, estimationConfig);

      let whichHand = '0';
      let state = '';
      let pinch = '';

      if (hands.length > 0) {

        // Draw mesh
        const ctx = canvasRef.current.getContext("2d");
        drawHand(hands, ctx);

        if (hands['0']['handedness'] === "Left") {
          whichHand = "2" //"Right"
        }
        else if (hands['0']['handedness'] === "Right") {
          whichHand = "1" //"Left"
        }

        let count = 0
        const landmarks = hands['0']['keypoints'];
        for (let i = 4; i <= 20; i += 4) {
          if (landmarks[i]['y'] < landmarks[i - 2]['y']) {
            count++
          }
        }

        if (count >= 4) {
          state = "isFingersOpen: True"
        }
        else {
          state = "isFingersOpen: False"
        }

        let length = Math.hypot(landmarks[4]['x'] - landmarks[8]['x'], landmarks[4]['y'] - landmarks[8]['y'])
        // console.log(length)
        if (length < 50) {
          pinch = "isPinch: True"
        } else {
          pinch = "isPinch: False"
        }
      }

      let result = whichHand + ' ' + state + ' ' + pinch;
      unityContext.send("Bird1", "SetGesture", result);
      unityContext.send("Hook", "SetGesture", result);

    }
  };

  runHandpose();

  return (
    <div className="App">
      <header className="App-header">

        {/* <Unity unityContext={unityContext} /> */}
        <Unity unityContext={unityContext} style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          top: 0,
          left: 0,
          textAlign: "center",
          zindex: 9,
          width: '100%',
          height: '100%',
        }} />

        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 160,
            height: 120,
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 160,
            height: 120,
          }}
        />
      </header>
    </div>
  );
}

export default App;
