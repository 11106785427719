// Points for fingers
const fingerJoints = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
};

// Infinity Gauntlet Style
const style = {
  0: { color: "yellow", size: 15 },
  1: { color: "gold", size: 6 },
  2: { color: "gold", size: 6 },
  3: { color: "gold", size: 6 },
  4: { color: "gold", size: 6 },
  5: { color: "gold", size: 6 },
  6: { color: "gold", size: 6 },
  7: { color: "gold", size: 6 },
  8: { color: "gold", size: 6 },
  9: { color: "gold", size: 6 },
  10: { color: "gold", size: 6 },
  11: { color: "gold", size: 6 },
  12: { color: "gold", size: 6 },
  13: { color: "gold", size: 6 },
  14: { color: "gold", size: 6 },
  15: { color: "gold", size: 6 },
  16: { color: "gold", size: 6 },
  17: { color: "gold", size: 6 },
  18: { color: "gold", size: 6 },
  19: { color: "gold", size: 6 },
  20: { color: "gold", size: 6 },
};

// Drawing function
export const drawHand = (predictions, ctx) => {
  // Check if we have predictions
  if (predictions.length > 0) {
    // Loop through each prediction
    predictions.forEach((prediction) => {
      // Grab landmarks
      const landmarks = prediction.keypoints;

      // Label Left/Right hand
      let whichHand = '';
      let colour = 'blue';
      if (prediction.handedness === "Left") {
        whichHand = "Right"
        colour = 'blue';
      }
      else if (prediction.handedness === "Right") {
        whichHand = "Left"
        colour = 'pink';
      }

      // ctx.beginPath();
      // ctx.font = '20px Arial';
      // ctx.textAlign = 'center';
      // ctx.textBaseline = 'top';
      // ctx.fillStyle = colour;
      // ctx.fillText(whichHand, landmarks[0]['x'], landmarks[0]['y'] + 30);
      // ctx.stroke();

      // let count = 0
      // for (let i = 4; i <= 20; i += 4) {
      //   if (landmarks[i]['y'] < landmarks[i - 2]['y']) {
      //     count++
      //   }
      // }
      // let state = '';
      // if (count >= 4) {
      //   state = "Open"
      // }
      // else {
      //   state = "Close"
      // }
      // ctx.beginPath();
      // ctx.font = '20px Arial';
      // ctx.textAlign = 'center';
      // ctx.textBaseline = 'top';
      // ctx.fillStyle = 'blue';
      // ctx.fillText(state, landmarks[0]['x'], landmarks[0]['y'] + 60);
      // ctx.stroke();

      // Loop through fingers
      for (let j = 0; j < Object.keys(fingerJoints).length; j++) {
        let finger = Object.keys(fingerJoints)[j];

        //  Loop through pairs of joints
        for (let k = 0; k < fingerJoints[finger].length - 1; k++) {
          // Get pairs of joints
          const firstJointIndex = fingerJoints[finger][k];
          const secondJointIndex = fingerJoints[finger][k + 1];

          // Draw path
          ctx.beginPath();
          ctx.moveTo(
            landmarks[firstJointIndex]['x'],
            landmarks[firstJointIndex]['y']
          );
          ctx.lineTo(
            landmarks[secondJointIndex]['x'],
            landmarks[secondJointIndex]['y']
          );
          ctx.strokeStyle = colour;
          ctx.lineWidth = 4;
          ctx.stroke();
        }
      }

      // Loop through landmarks and draw em
      for (let i = 0; i < landmarks.length; i++) {
        // Get x point
        const x = landmarks[i]['x'];
        // Get y point
        const y = landmarks[i]['y'];
        // Start drawing
        ctx.beginPath();
        ctx.arc(x, y, style[i]["size"], 0, 3 * Math.PI);

        // Set line color
        ctx.fillStyle = style[i]["color"];
        ctx.fill();
      }
    });
  }
};
